<template>
  <div class="exemption">
    <p class="exemption__email">{{ exemption.email }}</p>
    <div class="exemption__action">
      <v-btn class="exemption__action__btn" depressed @click="$refs.change.open()">
        <v-icon class="exemption__action__btn__icon">edit</v-icon>
      </v-btn>
      <v-btn class="exemption__action__btn" depressed @click="$refs.confirm.open()">
        <v-icon class="exemption__action__btn__icon">delete</v-icon>
      </v-btn>
    </div>
    <tag-change ref="change" :exemption="exemption" :tag="exemption.tag" />
    <confirm-dialog ref="confirm" title="アクセス権限の削除" :msg="dialogMsg(exemption.email)" @do-action="deleteAuthority()" />
  </div>
</template>

<script>
import TagChange from '@/components/exception/form/tag/change'
import ConfirmDialog from '@/components/common/confirm'

export default {
  components: { TagChange, ConfirmDialog },
  props: {
    // 月額費を払わずに利用できるユーザー情報
    exemption: {
      type: Object,
      requred: true
    }
  },
  computed: {
    /**
     * @return {String} 確認ダイアログに表示する文
     * @param {String} タグ名
     */
    dialogMsg: () => email => {
      return email + 'をL.D.W.から削除します。削除すると、L.D.W.アプリへのアクセスができなくなります。'
    }
  },
  methods: {
    /**
     * アクセス権限の削除
     */
    async deleteAuthority () {
      this.$store.commit('setSubmitting', true)

      await this.$store.dispatch('exemptions/deleteExemption', this.exemption.eid)

      this.$store.commit('setTelop', { show: true, msg: 'アクセス権限を削除しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.exemption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 $padding_width;
  &__email {
    margin: 0;
    overflow: hidden;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $black_lighten_color;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__action {
    min-width: 60px;
    margin-left: 10px;
    &__btn {
      transform: translateX(5px);
      &.v-btn:not(.v-btn--round).v-size--default {
        width: 30px;
        min-width: 0;
        height: 20px;
        padding: 0;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $white_color;
      }
      &__icon {
        font-family: $material-outlined;
        &.v-icon {
          font-size: 20px;
          color: $black_lighten_color;
        }
      }
    }
  }
}
</style>
