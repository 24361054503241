<template>
  <div class="navigation">
    <v-btn :class="active === '' ? 'navigation__item--active' : 'navigation__item'"
           depressed @click="select('')">
      未登録
    </v-btn>
    <v-btn :class="active === tag.taid ? 'navigation__item--active' : 'navigation__item'"
           depressed v-for="tag in tags" :key="tag.taid" @click="select(tag.taid)">
      {{ tag.name }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    // タグ一覧
    tags: {
      type: Array,
      requred: true
    }
  },
  data () {
    return {
      // 活性中のタグ
      active: ''
    }
  },
  methods: {
    /**
     * タグの選択
     * @param {String} taid タグのID
     */
    select (taid) {
      this.active = this.active === taid ? null : taid
      this.$emit('set-tag', this.active)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.navigation {
  box-sizing: border-box;
  width: 100%;
  max-width: $max_width;
  padding: 0 $padding_width;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &__item {
    margin-right: 10px;
    text-transform: none;
    border: 2px solid $orange_color;
    border-radius: 5px;
    &:last-of-type {
      margin-right: 0;
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 60px;
      height: 34px;
      padding: 0 15px;
      font-size: 1.2rem;
      color: $orange_color;
      background-color: $white_color;
    }
    &--active {
      @extend .navigation__item;
      &.v-btn:not(.v-btn--round).v-size--default {
        color: $white_color;
        background-color: $orange_color;
      }
    }
  }
}
</style>
