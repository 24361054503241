<template>
  <v-dialog  content-class="edit" v-model="dialog">
    <v-card class="edit__area">
      <v-card-title class="edit__area__title">タグの変更</v-card-title>
      <v-card-text class="edit__area__msg">
        {{ target }}のタグを「{{ tag.name }}」から変更します。変更したいタグを選択してください。
      </v-card-text>
      <div class="edit__area__tags">
        <v-btn :class="active === tag.taid ? 'edit__area__tags__item--active' : 'edit__area__tags__item'"
               depressed v-for="tag in tags" :key="tag.name" @click="select(tag.taid)">
          {{ tag.name }}
        </v-btn>
      </div>
      <v-btn class="edit__area__btn" :disabled="!canEdit" depressed @click="edit()">
        変更する
      </v-btn>
      <v-btn text class="edit__area__close" @click="doClose()">閉じる</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    // ユーザー情報
    user: {
      type: Object,
      requred: false
    },
    // 月額費を払わずに利用できるユーザー情報
    exemption: {
      type: Object,
      requred: false
    }
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      // 活性中のタグ
      active: ''
    }
  },
  mounted () {
    this.active = this.tag.taid
  },
  computed: {
    /**
     * @return {Object[]} タグ一覧
     */
    tags () {
      return this.$store.getters['tags/tags']
    },
    /**
     * @return {Object} タグ情報
     */
    tag () {
      return this.$store.getters['tags/tag'](this.exemption.taid)
    },
    /**
     * @return {String} タグを変更する対象（登録済み：ユーザー名 未登録：メールアドレス）
     */
    target () {
      return this.user ? this.user.name + 'さん' : this.exemption.email
    },
    /**
     * @return {Boolean} タグ名が変更可能かどうか
     */
    canEdit () {
      return this.active && this.exemption.taid !== this.active
    }
  },
  methods: {
    /**
     * ダイアログを開く
     * @return {Promise}
     */
    open () {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    /**
     * ダイアログを閉じる
     */
    doClose () {
      this.resolve(false)
      this.dialog = false
    },
    /**
     * タグの選択
     * @param {String} taid タグのID
     */
    select (taid) {
      this.active = this.active === taid ? null : taid
    },
    /**
     * タグ変更処理
     */
    async edit () {
      this.$store.commit('setSubmitting', true)

      const params = {
        taid: this.active,
        updatedAt: new Date()
      }

      await this.$store.dispatch('exemptions/updateExemptions', { eid: this.exemption.eid, params: params })

      this.$store.commit('setTelop', { show: true, msg: 'タグを変更しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.edit {
  &__area {
    width: 100%;
    padding: 20px 15px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    &__title {
      display: block;
      margin: 0;
      &.v-card__title {
        padding: 0;
        font-size: 1.4rem;
        font-weight: bold;
        color: $black_color;
      }
    }
    &__msg {
      margin: 20px 0 0;
      text-align: left;
      &.v-card__text {
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.7rem;
        color: $black_lighten_color;
      }
    }
    &__tags {
      box-sizing: border-box;
      width: 100%;
      max-width: $max_width;
      margin-top: 20px;
      overflow-x: auto;
      overflow-y: hidden;
      text-align: left;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      &__item {
        margin-right: 10px;
        text-transform: none;
        border: 2px solid $orange_color;
        border-radius: 5px;
        &:last-of-type {
          margin-right: 0;
        }
        &.v-btn:not(.v-btn--round).v-size--default {
          min-width: 60px;
          height: 34px;
          padding: 0 15px;
          font-size: 1.2rem;
          color: $orange_color;
          background-color: $white_color;
        }
        &--active {
          @extend .edit__area__tags__item;
          &.v-btn:not(.v-btn--round).v-size--default {
            color: $white_color;
            background-color: $orange_color;
          }
        }
      }
    }
    &__btn {
      display: block;
      margin: 60px auto 0;
      font-size: 1.2rem;
      color: $white_color;
      border-radius: 5px;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: 78px;
        height: 34px;
        padding: 0 15px;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $orange_color;
      }
      &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        color: $white_color !important;
        background-color: $orange_lighten_color !important;
      }
    }
    &__close {
      margin: 10px 30px 0;
      font-size: 1.4rem;
      font-weight: bold;
      color: $gray_darken_color;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 0;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
// vuetify用
.v-dialog.edit {
  width: 85%;
  max-width: 400px;
  background-color: white;
  border-radius: 15px;
  box-shadow: none;
}

.v-overlay--active {
  .v-overlay__scrim {
    background-color: $black_lighten_color !important;
    border: none;
    opacity: 0.7 !important;
  }
}
</style>
