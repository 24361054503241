<template>
  <div class="exception-edit" v-if="!isProcessing">
    <navigation class="exception-edit__tags" :tags="tags" @set-tag="setTag" />
    <p class="exception-edit__warning" v-if="taid === '' && unregisteredExemptions.length === 0">招待後、未登録のユーザーはいません。</p>
    <!-- 未登録ユーザー一覧タブ -->
    <template v-else-if="taid === ''">
      <unregistered class="exception-edit__element"
                    v-for="exemption in unregisteredExemptions" :key="exemption.eid" :exemption="exemption"/>
    </template>
    <!-- タグ登録ユーザー一覧タブ -->
    <template v-else>
      <template v-for="(exemption, index) in tagExemptions" >
        <!-- 登録済みユーザー -->
        <div class="exception-edit__element" v-if="exemption.uid" :key="exemption.eid">
          <user-detail :user="users[exemption.uid]" :email="emails[exemption.uid]"/>
          <div class="exception-edit__element__action">
            <v-btn class="exception-edit__element__action__btn" depressed @click="$refs.change[index].open()">
              <v-icon class="exception-edit__element__action__btn__icon">edit</v-icon>
            </v-btn>
            <v-btn class="exception-edit__element__action__btn" depressed @click="$refs.confirm[index].open()">
              <v-icon class="exception-edit__element__action__btn__icon">delete</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- 未登録ユーザー -->
        <unregistered class="exception-edit__element" v-else :exemption="exemption" :key="exemption.eid"/>
        <tag-change ref="change" :user="users[exemption.uid]" :taid="taid" :exemption="exemption" :key="index"/>
        <confirm-dialog ref="confirm" title="アクセス権限の削除" :msg="dialogMsg(exemption.uid ? users[exemption.uid].name : exemption.email)" @do-action="deleteAuthority(exemption)" :key="exemption.email"/>
      </template>
    </template>
  </div>
</template>

<script>
import Navigation from '@/components/exception/navigation'
import UserDetail from '@/components/common/user/detail'
import Unregistered from '@/components/exception/unregistered'
import TagChange from '@/components/exception/form/tag/change'
import ConfirmDialog from '@/components/common/confirm'

export default {
  name: 'exception_edit',
  components: { Navigation, UserDetail, Unregistered, TagChange, ConfirmDialog },
  data () {
    return {
      // 選択中のタグ
      taid: ''
    }
  },
  async mounted () {
    const promises = []
    if (this.tags.length === 0) promises.push(this.$store.dispatch('tags/getTags'))
    if (this.exemptions.length === 0) promises.push(this.$store.dispatch('exemptions/getExemptions'))
    // ユーザーのメールアドレス取得
    if (Object.keys(this.emails).length === 0) promises.push(this.$store.dispatch('functions/getAllEmails'))

    await Promise.all(promises)

    // 例外ユーザーの取得を行う
    const userPromises = []
    this.exemptions.forEach(exemption => {
      if (exemption.uid && !this.users[exemption.uid]) userPromises.push(this.$store.dispatch('users/getUser', exemption.uid))
    })

    await Promise.all(userPromises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object[]} タグ一覧
     */
    tags () {
      return this.$store.getters['tags/tags']
    },
    /**
     * @return {Object[]} 例外情報一覧
     */
    exemptions () {
      return this.$store.getters['exemptions/exemptions']
    },
    /**
     * @return {Object[]} 未登録の例外情報一覧
     */
    unregisteredExemptions () {
      return this.$store.getters['exemptions/exemptions'].filter(exemption => !exemption.uid)
    },
    /**
     * @return {Object[]} 登録済みかつ選択中のタグを持つ例外情報一覧
     */
    tagExemptions () {
      return this.exemptions.filter(exemption => exemption.taid === this.taid)
    },
    /**
     * @return {Object[]} ユーザー一覧
     */
    users () {
      return this.$store.getters['users/users']
    },
    /**
     * @return {Object} ユーザーのメールアドレス一覧
     */
    emails () {
      return this.$store.getters.emails
    },
    /**
     * @return {String} 確認ダイアログに表示する文
     * @param {String} タグ名
     */
    dialogMsg: () => name => {
      return name + 'さんをL.D.W.から削除します。削除すると、L.D.W.アプリへのアクセスができなくなります。'
    }
  },
  methods: {
    /**
     * 選択中のタグに値の代入
     * @param {String} taid 格納する変数値
     */
    setTag (taid) {
      this.taid = taid
    },
    /**
     * アクセス権限の削除
     * @param {Object} exemption アクセス権限を削除する例外情報
     */
    async deleteAuthority (exemption) {
      this.$store.commit('setSubmitting', true)

      const params = {
        isDeleted: true,
        updatedAt: new Date()
      }

      const user = this.users[exemption.uid]

      await this.$store.dispatch('users/updateUser', { uid: exemption.uid, params: params })

      // 例外ユーザーがFBグループに未加入であればauth、画像、例外情報を削除する
      // 加入済みの場合はFBグループ脱退処理時に行う
      if (!user.isFacebookMember) {
        const promises = []

        promises.push(this.$store.dispatch('exemptions/deleteExemption', exemption.eid))
        promises.push(this.$store.dispatch('cloudstorage/deleteImage', user.icon))
        promises.push(this.$store.dispatch('functions/deleteAuthUser', exemption.uid))

        await Promise.all(promises)
      }

      this.$store.commit('setTelop', { show: true, msg: 'アクセス権限を削除しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';
@import '@/assets/sass/fontfamily.scss';

.exception-edit {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height 0;
  margin: $header_height auto 0 auto;
  &__warning {
    margin-top: 30px;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
  &__element {
    position: relative;
    margin-top: 20px;
    &:first-child {
      margin-top: 30px;
    }
    &__action {
      position: absolute;
      top: 0;
      right: $padding_width;
      min-width: 60px;
      margin-left: 10px;
      &__btn {
        transform: translateX(5px);
        &.v-btn:not(.v-btn--round).v-size--default {
          width: 30px;
          min-width: auto;
          height: 20px;
          padding: 0;
        }
        &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
          background-color: $white_color;
        }
        &__icon {
          font-family: $material-outlined;
          &.v-icon {
            font-size: 20px;
            color: $black_lighten_color;
          }
        }
      }
    }
  }
}
</style>
